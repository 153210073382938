import React from "react";
import "./index.css";
import HeroSection from "../Home/HeroSection";
import DemoCard from "../Home/DemoCard";
import Footer from "../../common/Footer";

const SlackPage = () => {
  return (
    <>
       <HeroSection />
    <div className="slick-page-main-container">
      <span className="slack-integration-heading">Integration with Slack </span>
      <ol>
        <li>
          <span>Seamless Task Management:</span> <br />
          <p className="point-disc">
          By integrating Slack with Misgenie, you can manage tasks like checking
          in, checking out, and submitting leave requests directly from Slack,
          eliminating the need to switch between platforms.
          </p>
        </li>
        <li>
          <span>Real-time Notifications:</span> <br />
          <p className="point-disc">
          Receive real-time notifications in Slack for all key actions,
          including leave requests, approvals, and updates on your tasks,
          ensuring you're always in the loop.
          </p>
        </li>
        <li>
          <span>Increased Efficiency:</span> <br />
          <p className="point-disc"> 
          Streamline your workflow by accessing Misgenie's features directly
          from Slack without needing to navigate through different apps or
          systems, saving you time and improving productivity.
          </p>
        </li>
        <li>
          <span>Centralized Communication:</span> <br />
          <p className="point-disc"> 
          Consolidate all your work-related communication and task management
          within Slack, making it easier to track progress, discuss with
          teammates, and stay organized in one central hub.
          </p>
        </li>
        <li>
          <span>Easy Leave & Time Management:</span> <br />
          <p className="point-disc"> 
          Submit leave requests, track hours, and manage your attendance
          directly through Slack, reducing administrative workload and providing
          better transparency.
          </p>
        </li>
      </ol>
      <span className="slack-integration--bottom-disc">
        You can search for Misgenie Slack Integration in your Slack app
        directory or visit Misgenie's integration page. You can also use a
        simple format like this:
      </span>
      <span className="slack-integration--bottom-heading">Click here to add Slack to Misgenie</span>
      <a href="https://slack.com/oauth/v2/authorize?client_id=8545176351107.8530201776487&scope=channels:history,chat:write,commands,im:history,im:read,im:write,incoming-webhook,mpim:history,users.profile:read,users:read&user_scope=channels:history,chat:write,im:history,im:read,mpim:write">
        <img
          alt="Add Misgenie to Slack"
          height="70"
          width="200"
          src="https://platform.slack-edge.com/img/add_to_slack.png"
          srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
        />
      </a>
    </div>
    <DemoCard />
    <Footer />
    </>
  );
};

export default SlackPage;